<template>
  <router-view/>
</template>

<script>
import axios from 'axios'
export default {
  name: "App",
  created () {
    this.chechMaintenance()
  },
  watch: {
    $route(to) {
      this.$router.push(to);
    }
  },
  methods: {
    chechMaintenance(){
      axios.get('status.json')
      .then((response) => {
        if(response.data.env !== "production"){
          this.$router.push({name: 'Maintenance'})
        }
      })
      .catch(() =>{
        this.$router.push({name: 'Maintenance'})
      })
    }
  }
}
</script>