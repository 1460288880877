import { render, staticRenderFns } from "./App.vue?vue&type=template&id=309856d5&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../local-cache/custom/ece6a99d81ee033034768cd0386afeb08988d587012316c712d0e8523080cbc5/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports